// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// app/javascript/application.js
import Rails from "@rails/ujs";
import * as ActiveStorage from "@rails/activestorage";
import "channels";
import "owl.carousel2"; // Asumiendo que es necesario para la aplicación principal
import "owl.carousel2/dist/assets/owl.carousel.css"; // Asumiendo que es necesario para la aplicación principal

import "../stylesheets/application"
//import 'bootstrap-icons/font/bootstrap-icons.css'
import 'select2'
import 'select2/dist/css/select2.css'
import 'select2-bootstrap-theme/dist/select2-bootstrap.min.css'


Rails.start();
ActiveStorage.start();

require('jquery')
require("jquery-ui")
require("jquery-mask-plugin")
global.toastr = require("toastr")

$(function(){
  console.log('Loaded.');
});

$(function() {
    $(".images").sortable({
      update: function(e, ui){
        ui.placeholder.height(ui.item.height());
        Rails.ajax({
          url: $(this).data("url"),
          type: "PATCH",
          data: $(this).sortable('serialize')
        });
      }
    }).disableSelection();


    $( "#sortable" ).sortable({
      update: function(e, ui){
        ui.placeholder.height(ui.item.height());
        Rails.ajax({
          url: $(this).data("url"),
          type: "PATCH",
          data: $(this).sortable('serialize')
        });
      }
    }).disableSelection();


  });
  $.jMaskGlobals.watchDataMask = true;
import "controllers"

window.initMap = function(...args){
  const event = document.createEvent("Events")
  event.initEvent("google-maps-callback", true, true)
  event.args = args
  window.dispatchEvent(event)
}